<template>
    <form-group :group-id="$route.params.code" />
</template>

<script>
    import FormGroup from '@/components/FormGroup'
    export default {
        components: { FormGroup }
    }
</script>
