<template>
    <div class="m-auto shadow-md rounded-md bg-white mt-4">
        <form @submit.prevent="actionSubmit()">
            <div class="p-7 space-y-5">
                <div>
                    <label class="font-semibold">Group Name</label><br>
                    <input class="form-control" v-model="form.title" type="text" value="" autocomplete="off" required>
                </div>
                <div>
                    <label class="font-semibold text-base"> Group Category </label>
                    <select v-model="form.category_id" class="form-control" required>
                        <option value="">Select Group Category</option>
                        <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.title }}</option>
                    </select>
                </div>
                <div>
                    <label class="font-semibold text-base">Group description</label>
                    <textarea class="form-control h-32"  v-model="form.description" type="text" value="" autocomplete="off" required></textarea>
                </div>
                <div class="space-y-2">
                    <label class="font-semibold text-base">Group Privacy</label>
                    <div> Anyone can see who's in the group and what they post. </div>
                    <select v-model="form.privacy" class="form-control" required>
                        <option value="">Select Group Privacy</option>
                        <option value="private">Private</option>
                        <option value="unlisted">Unlisted</option>
                        <option value="public">Public</option>
                    </select>
                </div>
                <div>
                    <label class="font-semibold text-base">The reason for creating this group</label>
                    <textarea class="form-control h-32" value="" v-model="form.reason" type="text" autocomplete="off" required></textarea>
                </div>
                <div>
                    <label class="font-semibold text-base">Examples of topics to be discussed</label>
                    <textarea class="form-control h-32" value="" v-model="form.interested_topics" type="text" autocomplete="off" required></textarea>
                </div>
                <div>
                    <label class="font-semibold">For MBKM ?</label>
                    <b-form-checkbox @change="forMbkm()" v-model="for_mbkm" name="check-button" switch>Check if group for MBKM program</b-form-checkbox>
                </div>
                <div v-if="for_mbkm">
                    <label class="font-semibold text-base">MBKM</label>
                    <!-- <select v-model="form.mbkm_id" class="form-control">
                        <option value="0">Select MKBKM</option>
                        <option :value="mbkm.id" v-for="mbkm in mbkms" :key="mbkm.id">{{ mbkm.title }}</option>
                    </select> -->
                    <multiselect v-model="form.mbkm_id" :options="mbkms" :multiple="true" :close-on-select="true" label="title" track-by="id"></multiselect>
                </div>
                <div>
                    <label class="font-semibold text-base">Preview</label><br>
                    <div class="image-uploader">
                        <input type="file" ref="preview" @change="onFileSelected($event, 'preview')">
                        <span @click="$refs.preview.click()"><Icon icon="bi:cloud-upload" /></span>
                        <img :src="preview ? preview : $placeholder2" alt="Preview" class="w-80">
                    </div>
                </div>
                <div>
                    <label class="font-semibold text-base">Logo</label><br>
                    <div class="image-uploader">
                        <input type="file" ref="logo" @change="onFileSelected($event, 'logo')">
                        <span @click="$refs.logo.click()"><Icon icon="bi:cloud-upload" /></span>
                        <img :src="logo ? logo : $placeholder2" alt="Logo" style="width: 150px;">
                    </div>
                </div>
            </div>
            <!-- form footer -->
            <div class="border-t flex justify-content-end border-gray-100 p-6">
                <button class="lg:w-1/3 h-9 lg:px-5 rounded-md bg-blue-600 text-white space-x-1.5 px-5">
                    Save
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'
    import { Icon } from '@iconify/vue2'
    import { mapActions } from 'vuex'
    import Multiselect from 'vue-multiselect'

    export default {
        name: 'CreateGroup',
        components: {
            Icon,
            Multiselect
        },
        props: {
            groupId: {
                type: String,
                default: ''
            }
        },
        data () {
            return {
                categories: [],
                mbkms: [],
                for_mbkm: 0,
                form: {
                    title: '',
                    category_id: '',
                    mbkm_id: '',
                    description: '',
                    privacy: '',
                    reason: '',
                    interested_topics: '',
                    preview: '',
                    logo: ''
                },
                preview: '',
                logo: ''
            }
        },
        async created () {
            try {
                const res = await axios.get('category_group')
                const mbkms = await axios.get('mbkm_select')
                this.categories = res.data.result
                this.mbkms = mbkms.data.result
            } catch (e) {
                console.error(e)
            }
            if (this.groupId) {
                this.show(this.groupId)
                    // eslint-disable-next-line camelcase
                    .then(({ title, category_id, mbkm_id, description, privacy, reason, interested_topics, preview, logo }) => {
                        this.form = {
                            title,
                            category_id,
                            mbkm_id,
                            description,
                            privacy,
                            reason,
                            interested_topics
                        }
                        this.preview = preview
                        this.logo = logo
                        if (this.form.mbkm_id.length > 0) {
                            this.for_mbkm = true
                        }
                    })
            }
        },
        methods: {
            ...mapActions('groups', ['show', 'update']),
            actionSubmit () {
                const $this = this
                this.update({
                    slug: this.groupId,
                    data: this.form
                }).then((data) => {
                    this.$swal({
                        imageUrl: require('@/assets/images/vector-success.png'),
                        imageWidth: 400,
                        title: 'Perubahan telah disimpan',
                        text: data.message
                    }).then(function () {
                        $this.$router.go('/app/group/' + $this.groupId)
                    })
                }).catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                })
            },
            onFileSelected (e, target) {
                this.form[target] = e.target.files[0]
                this[target] = URL.createObjectURL(this.form[target])
            },
            forMbkm () {
                if (!this.for_mbkm) {
                    this.form.mbkm_id = []
                }
            }
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
